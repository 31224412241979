import { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/pages/About.scss";
import Seo from "../components/Seo";
import KeysImage from "../assets/images/keys-image.png";
import ChooseImage from "../assets/images/choose-image.png";
import PlusIcon from "../assets/images/icons/plus.png";
import LessIcon from "../assets/images/icons/less.png";

export default function About() {
  const { t } = useTranslation("about");
  const [block, setBlock] = useState("one");
  return (
    <>
      <Seo title={t("hero-title")} />
      <Header />
      <main className="about">
        <section className="about__hero">
          <div className="about__hero-container">
            <div className="about__hero-box" />
            <div className="about__hero-box">
              <h1 className="about__hero-title">{t("hero-title")}</h1>
              <p className="about__hero-text">{t("hero-subtitle")}</p>
            </div>
          </div>
        </section>
        <section className="about__intro">
          <div className="about__intro-container">
            <h2 className="about__intro-title">{t("intro-title")}</h2>
            <div className="about__intro-grid">
              <div className="about__intro-box">
                <p className="about__intro-text">{t("intro-paragraph-one")}</p>
              </div>
              <div className="about__intro-box">
                <p className="about__intro-text">
                  {t("intro-paragraph-two")}
                  <span className="about__intro-text about__intro-text--bold">
                    {t("intro-paragraph-bold")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="about__keys">
          <div className="about__keys-container">
            <div className="about__keys-box about__keys-box--text">
              <div className="about__keys-box-wrapper">
                <article className="about__keys-wrapper">
                  <button
                    onClick={() => setBlock("one")}
                    type="button"
                    className="about__keys-title"
                  >
                    {t("mission-title")}
                    {block === "one" && (
                      <img
                        src={LessIcon}
                        alt="Less"
                        className="about__keys-icon"
                      />
                    )}
                    {block !== "one" && (
                      <img
                        src={PlusIcon}
                        alt="Plus"
                        className="about__keys-icon"
                      />
                    )}
                  </button>
                  {block === "one" && (
                    <p className="about__keys-paragraph">
                      {t("mission-paragraph")}
                    </p>
                  )}
                </article>
                <article className="about__keys-wrapper">
                  <button
                    onClick={() => setBlock("two")}
                    type="button"
                    className="about__keys-title"
                  >
                    {t("vision-title")}
                    {block === "two" && (
                      <img
                        src={LessIcon}
                        alt="Less"
                        className="about__keys-icon"
                      />
                    )}
                    {block !== "two" && (
                      <img
                        src={PlusIcon}
                        alt="Plus"
                        className="about__keys-icon"
                      />
                    )}
                  </button>
                  {block === "two" && (
                    <p className="about__keys-paragraph">
                      {t("vision-paragraph")}
                    </p>
                  )}
                </article>
                <article className="about__keys-wrapper">
                  <button
                    onClick={() => setBlock("three")}
                    type="button"
                    className="about__keys-title"
                  >
                    {t("values-title")}
                    {block === "three" && (
                      <img
                        src={LessIcon}
                        alt="Less"
                        className="about__keys-icon"
                      />
                    )}
                    {block !== "three" && (
                      <img
                        src={PlusIcon}
                        alt="Plus"
                        className="about__keys-icon"
                      />
                    )}
                  </button>
                  {block === "three" && (
                    <ul className="about__keys-list">
                      <li className="about__keys-item">
                        {t("values-list-one")}
                      </li>
                      <li className="about__keys-item">
                        {t("values-list-two")}
                      </li>
                      <li className="about__keys-item">
                        {t("values-list-three")}
                      </li>
                      <li className="about__keys-item">
                        {t("values-list-four")}
                      </li>
                      <li className="about__keys-item">
                        {t("values-list-five")}
                      </li>
                      <li className="about__keys-item">
                        {t("values-list-six")}
                      </li>
                    </ul>
                  )}
                </article>
              </div>
            </div>
            <div className="about__keys-box">
              <img src={KeysImage} alt="Keys" className="about__keys-image" />
            </div>
          </div>
        </section>
        <section className="about__choose">
          <div className="about__choose-container">
            <div className="about__choose-box about__choose-box--image">
              <img src={ChooseImage} alt="" className="about__choose-image" />
            </div>
            <div className="about__choose-box">
              <h3 className="about__choose-title">{t("choose-title")}</h3>
              <p className="about__choose-paragraph">{t("choose-paragraph")}</p>
              <ul className="about__choose-list">
                <li className="about__choose-item">{t("choose-list-one")}</li>
                <li className="about__choose-item">{t("choose-list-two")}</li>
                <li className="about__choose-item">{t("choose-list-three")}</li>
                <li className="about__choose-item">{t("choose-list-four")}</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="about__more">
          <div className="about__more-container">
            <div className="about__more-box">
              <h3 className="about__more-title">{t("more-title")}</h3>
            </div>
            <div className="about__more-box">
              <p className="about__more-paragraph">{t("more-paragraph")}</p>
              <ul className="about__more-list">
                <li className="about__more-item">{t("more-list-one")}</li>
                <li className="about__more-item">{t("more-list-two")}</li>
                <li className="about__more-item">{t("more-list-three")}</li>
                <li className="about__more-item">{t("more-list-four")}</li>
              </ul>
              <p className="about__more-paragraph">{t("more-paragraph-two")}</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
